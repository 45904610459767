// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.


export const environment = {
  production: false,
  // apiURL: 'https://optimize.echoapp.biz/portal/api/V1/',

  // apiURL: 'https://dev.echoapp.biz/portal/api/V1/',
  apiURL: 'https://test.echoapp.biz/portal/api/V1/',
  // apiURL: 'https://api.echoapp.biz/api/V1/',
  // fileuploadURL: 'https://api.echoapp.biz/api/U1/',
  fileuploadURL: 'https://test.echoapp.biz/portal/api/U1/',
  // apiURL: 'https://echoapp.biz/portal/api/V1/',
  // IEOURL: 'https://sandbox.iexapis.com',
  IEOURL: 'https://cloud.iexapis.com/',
  IEOVersion: 'stable',
  // alpaca baseurl & auth Key //
  AlpaURL: 'https://paper-api.alpaca.markets/v2/',
  AlpaKeyId: 'PKP3UDQ2OXD6VCZAHXMS',
  AlpaSecretKey: '5KDCUGqEVLwKxTb4fqj0tHN89HlNGWFd4ZfYS9ep'
};
